<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" page_title="lessonlearn" />

    <v-card class="mx-3 my-5 rounded-lg">
      <template>
        <v-container fluid>
          <v-row class="d-flex">
            <v-col cols="12" lg="5" md="6" sm="12" class="pa-5">
              <v-img
                alt="OrgImg"
                :src="CaseStudy.caseImage"
                class="orgImg"
              ></v-img>
            </v-col>

            <v-col cols="12" lg="6" md="7" class="pt-5 Casetext">
              <v-card-title class="Casetext">{{
                CaseStudy.caseTitle
              }}</v-card-title>

              <v-card-subtitle
                style="font-size: 18px; opacity: 0.8; line-height: 25px"
                class="mt-3"
              >
                <div>
                  <p class="breaktext">
                    {{ CaseStudy.wordContent }}{{ CaseStudy.id }}
                  </p>
                </div>
              </v-card-subtitle>

              <v-row no-gutters v-if="hasLink">
                <v-col class="d-flex pl-4">
                  <p
                    class="mb-0 mr-12"
                    style="font-weight: bold; font-size: 20px"
                  >
                    {{ $t("link") }}
                  </p>
                  <p
                    style="
                      font-size: 12px;
                      opacity: 0.8;
                      margin-top: 35px;
                      cursor: pointer;
                    "
                    class="mb-0 mt-1 textlink text-decoration-underline"
                    target="_blank"
                    @click="clickLink(CaseStudy.link)"
                  >
                    {{ cutText(CaseStudy.link) }}
                  </p>
                </v-col>
              </v-row>
              <v-card-actions
                class="d-flex pt-6"
                style="justify-content: center !important"
              >
                <v-btn
                  v-if="
                    CaseStudy.pdfUrl != null &&
                    CaseStudy.pdfUrl.trim().length > 0
                  "
                  depressed
                  color="#A6CC39"
                  @click="clickPDFLink(CaseStudy.pdfUrl)"
                  class="login-phone text-capitalize"
                  download
                  >{{ $t("DownloadPDF") }}</v-btn
                >
              </v-card-actions>
            </v-col>
            <v-col>
              <v-icon
                :color="Addfav == true ? '#FF6060' : ''"
                @click="toggleCheck"
                class="heart-icon"
                >{{ heartIcon }}</v-icon
              >
            </v-col>
          </v-row>
        </v-container>
      </template>
      <div class="test">
        <div class="border1"></div>
      </div>
      <template>
        <div class="d-flex" style="justify-content: center">
          <div
            style="height: 1px; width: 95%; background-color: #00000027"
          ></div>
        </div>
        <template>
          <div class="scrollbar-container mt-7">
            <div class="video-scrollbar">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="pl-3"
                  style="
                    display: flex;
                    justify-content: center;
                    vertical-align: middle;
                  "
                >
                  <v-avatar size="45" class="marginRight-6">
                    <v-img
                      v-if="profileImg === null"
                      src="@/assets/defaultprofile.png"
                    ></v-img>
                    <v-img v-else :src="profileImg"></v-img>
                  </v-avatar>
                  <v-text-field
                    v-model="new_comment"
                    :label="$t('writeacomment')"
                    outlined
                    @input="checkCommentLength(new_comment, 1)"
                    dense
                    class="mt-1 comment-textbox"
                    hide-details="auto"
                    maxlength="100"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="py-3"
                  style="display: flex; justify-content: end"
                >
                  <v-btn
                    depressed
                    text
                    outlined
                    width="80"
                    height="35"
                    class="text-capitalize ml-5"
                    style="border-radius: 8px; margin-left: 5px"
                    @click="(new_comment = ''), (mainComNull = true)"
                  >
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-btn
                    :disabled="mainComNull"
                    width="80"
                    height="35"
                    class="text-capitalize btn_hover_effect ml-5"
                    style="color: #ffff; border-radius: 8px"
                    color="#A6CC39"
                    @click="AddCommentUnderVideo()"
                  >
                    {{ $t("send") }}
                  </v-btn>
                </v-col>
              </v-row>
              <div class="comment-paddingLeft4">
                <p class="mb-0" style="font-weight: bold; color: black">
                  {{ messageItems.length != 0 ? messageItems.length : "0" }}
                  {{ $t("comments") }}
                </p>
              </div>
              <div
                class="comment-paddingLeft4 pt-3"
                v-for="(comment, index) in messageItems"
                :key="index"
              >
                <v-row no-gutters>
                  <!-- outer comment part -->
                  <v-col cols="11" md="11" sm="11" style="display: flex">
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        style="display: flex; flex-direction: row"
                      >
                        <v-avatar
                          justify="center"
                          align="center"
                          size="35"
                          class="marginRight-6"
                        >
                          <v-img
                            v-if="comment.profileImage"
                            :src="comment.profileImage"
                          ></v-img>
                          <v-img
                            v-else
                            src="@/assets/defaultprofile.png"
                          ></v-img>
                        </v-avatar>
                        <!-- show comment/show edit text-box -->
                        <div class="d-flex flex-column" style="width: 100%">
                          <div v-if="!comment.editCommentFlag">
                            <p class="mb-1">
                              {{ comment.fullname }}
                              {{ comment.commentedTime }}
                              <br />
                              {{ comment.commentMessage }}
                            </p>
                          </div>
                          <!-- when edit comment show this -->
                          <div v-else>
                            <v-row no-gutters>
                              <v-col cols="12" class="d-flex">
                                <v-text-field
                                  :placeholder="$t('writeacomment')"
                                  v-model="comment.tempComment"
                                  @input="
                                    checkCommentLength(comment.tempComment, 2)
                                  "
                                  hide-details="auto"
                                  maxlength="100"
                                  class="comment-textbox"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row class="mt-0">
                              <v-col
                                style="
                                  display: flex;
                                  justify-content: end;
                                  align-items: center;
                                "
                              >
                                <v-btn
                                  depressed
                                  text
                                  outlined
                                  width="80"
                                  height="35"
                                  class="text-capitalize"
                                  style="border-radius: 8px"
                                  @click="cancelComment(comment)"
                                >
                                  {{ $t("cancel") }}
                                </v-btn>
                                <v-btn
                                  :disabled="commentNull"
                                  width="100"
                                  height="35"
                                  class="text-capitalize btn_hover_effect ml-5"
                                  style="
                                    color: #ffff;
                                    border-radius: 8px;
                                    padding: 0.3rem 0px 0.3rem 0px;
                                  "
                                  color="#A6CC39"
                                  @click="updateComment(comment)"
                                >
                                  {{ $t("update") }}
                                </v-btn>
                              </v-col>
                            </v-row>
                          </div>
                          <!-- reply btn under each comment -->
                          <div class="pt-1">
                            <v-btn
                              style="
                                font-size: 14px;
                                min-width: 54px;
                                min-height: 22px;
                                height: auto !important;
                                justify-content: center;
                                background-color: #f0f0f0;
                                opacity: 0.42;
                                color: #424242;
                                opacity: 1;
                                border-radius: 4px;
                                padding: 0;
                              "
                              text
                              class="text-capitalize"
                              @click="ClickCommentReply(comment)"
                            >
                              {{ $t("reply") }}
                            </v-btn>
                          </div>
                          <!-- show replies count text under reply btn -->
                          <div
                            v-if="comment.replyCommentLists.length != 0"
                            @click="showReplylist(comment)"
                          >
                            <p
                              style="
                                color: blue;
                                cursor: pointer;
                                font-size: 14px;
                                text-transform: lowercase;
                              "
                              class="mb-1"
                            >
                              <v-icon
                                style="color: blue"
                                v-if="comment.replyFlag"
                                >mdi-chevron-down</v-icon
                              >
                              <v-icon v-else style="color: blue"
                                >mdi-chevron-up</v-icon
                              >{{ comment.replyCommentLists.length }}
                              {{
                                comment.replyCommentLists.length > 1
                                  ? $t("replies")
                                  : $t("reply")
                              }}
                            </p>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- dot actions for editing comments -->
                  <v-col
                    v-if="
                      comment.memberID == loginmemberID ||
                      loginmemberID == memberID
                    "
                    cols="1"
                    md="1"
                    class="px-0"
                    style="display: flex; justify-content: end"
                  >
                    <template>
                      <div class="d-flex" style="margin-top: -5px">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              text
                              v-bind="attrs"
                              v-on="on"
                              style="min-width: 32px !important;padding: 0 !important;"
                            >
                              <v-icon style="color: #424242"
                                >mdi-dots-vertical</v-icon
                              >
                            </v-btn>
                          </template>

                          <v-list class="d-flex flex-column align-start">
                            <div>
                              <v-list-item
                                class="list-item-padding"
                                v-show="loginmemberID == comment.memberID"
                              >
                                <v-btn
                                  color="#424242"
                                  style="width: 100px; justify-content: left"
                                  text
                                  class="text-capitalize"
                                  @click="editComment(comment)"
                                >
                                  <v-icon left>mdi-square-edit-outline</v-icon>
                                  {{ $t("edit") }}
                                </v-btn>
                              </v-list-item>
                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="#FF6060"
                                  style="width: 100px; justify-content: left"
                                  text
                                  class="text-capitalize"
                                  @click="checkMessageDelete(comment)"
                                >
                                  <v-icon left>mdi-delete-outline</v-icon>
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-list-item>
                            </div>
                          </v-list>
                        </v-menu>
                      </div>
                    </template>
                  </v-col>
                </v-row>
                <!-- reply text box to outer comment -->
                <v-row
                  no-gutters
                  v-if="comment.showOuterCommentBox"
                  class="mt-3"
                >
                  <v-col
                    cols="11"
                    sm="11"
                    md="11"
                    class="reply-textbox-paddingLeft d-flex flex-column"
                  >
                    <div class="d-flex" style="width: 100%">
                      <v-avatar
                        class="marginRight-6"
                        justify="center"
                        align="center"
                        size="40"
                      >
                        <v-img
                          v-if="profileImg === null"
                          src="@/assets/defaultprofile.png"
                        ></v-img>
                        <v-img v-else :src="profileImg"></v-img>
                      </v-avatar>
                      <div class="d-flex" style="width: 100%">
                        <v-text-field
                          :placeholder="$t('writeareply')"
                          v-model="comment.tempReplyMsg"
                          @input="checkCommentLength(comment.tempReplyMsg, 3)"
                          hide-details
                          class="comment-textbox"
                          dense
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="mt-3 d-flex justify-end" style="width: 100%">
                      <v-btn
                        depressed
                        text
                        outlined
                        width="80"
                        height="35"
                        class="text-capitalize ml-5"
                        style="border-radius: 8px; margin-left: 5px"
                        @click="
                          (comment.tempReplyMsg = ''),
                            (mainComNull = true),
                            (comment.showOuterCommentBox = false)
                        "
                      >
                        {{ $t("cancel") }}
                      </v-btn>
                      <v-btn
                        :disabled="replyCommentNull"
                        class="text-capitalize btn_hover_effect ml-5"
                        style="
                          color: #ffff;
                          border-radius: 8px;
                          font-size: 14px;
                          min-width: 80px !important;
                          min-height: 35px !important;
                          width: auto !important;
                          padding: 0;
                        "
                        color="#A6CC39"
                        @click="AddReplyToComment(comment, null)"
                      >
                        {{ $t("reply") }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <!-- replies of each related comment -->
                <v-row
                  class="mt-1 comment-paddingLeft4"
                  v-for="(repcomment, repIndex) in comment.replyCommentLists"
                  :key="repIndex"
                  v-show="comment.replyFlag"
                >
                  <v-col
                    cols="11"
                    md="11"
                    sm="11"
                    class="reply-textbox-paddingLeft d-flex flex-column"
                  >
                    <div class="d-flex" style="width: 100%">
                      <v-avatar
                        class="marginRight-6"
                        justify="center"
                        align="center"
                        size="35"
                      >
                        <v-img
                          v-if="repcomment.profileImage"
                          :src="repcomment.profileImage"
                        ></v-img>
                        <v-img v-else src="@/assets/defaultprofile.png"></v-img>
                      </v-avatar>
                      <div class="d-flex flex-column" style="width: 100%">
                        <p class="mb-0">
                          {{ repcomment.fullname }}
                          {{ repcomment.commentedTime }}
                        </p>
                        <div
                          v-if="!repcomment.showInside_ReplyBox"
                          style="display: flex; align-items: center"
                        >
                          <p
                            style="color: #34b4eb; margin-right: 10px"
                            class="mb-0"
                          >
                            {{ repcomment.replyFullname }}
                          </p>
                        </div>
                        <div v-if="!repcomment.showEditReplyBox" class="mb-2">
                          {{ repcomment.replyMessage }}
                        </div>
                        <div v-else>
                          <div>
                            <v-text-field
                              :placeholder="$t('writeareply')"
                              v-model="repcomment.tempEditReplyText"
                              @input="
                                checkCommentLength(
                                  repcomment.tempEditReplyText,
                                  4
                                )
                              "
                              hide-details
                              class="comment-textbox"
                              dense
                            ></v-text-field>
                          </div>
                          <div
                            class="mt-3 d-flex justify-end"
                            style="width: 100%"
                          >
                            <v-btn
                              depressed
                              text
                              outlined
                              width="80"
                              height="35"
                              class="text-capitalize"
                              style="border-radius: 8px"
                              @click="
                                () => {
                                  repcomment.showEditReplyBox = false;
                                  repcomment.tempEditReplyText = '';
                                }
                              "
                            >
                              {{ $t("cancel") }}
                            </v-btn>
                            <v-btn
                              :disabled="editReplyNull"
                              width="100"
                              height="35"
                              class="text-capitalize btn_hover_effect ml-5"
                              style="
                                color: #ffff;
                                border-radius: 8px;
                                padding: 0.3rem 0px 0.3rem 0px;
                              "
                              color="#A6CC39"
                              @click="updateReplyMessage(repcomment, comment)"
                            >
                              {{ $t("update") }}
                            </v-btn>
                          </div>
                        </div>
                        <div>
                          <v-btn
                            style="
                              font-size: 14px;
                              min-width: 54px;
                              min-height: 22px;
                              height: auto !important;
                              justify-content: center;
                              background-color: #f0f0f0;
                              opacity: 0.42;
                              color: #424242;
                              opacity: 1;
                              border-radius: 4px;
                              padding: 0;
                            "
                            text
                            class="text-capitalize"
                            v-if="!repcomment.showInside_ReplyBox"
                            @click="replyToInnerComment(repcomment)"
                          >
                            {{ $t("reply") }}
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <!-- dot action btns of inner reply comments -->
                  <v-col
                    cols="1"
                    md="1"
                    class="px-0"
                    style="display: flex; justify-content: end"
                    v-if="
                      repcomment.memberID == loginmemberID ||
                      loginmemberID == memberID
                    "
                  >
                    <template>
                      <div class="d-flex" style="margin-top: -5px">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              text
                              v-bind="attrs"
                              v-on="on"
                              class="mr-2"
                              style="min-width: 32px !important;padding: 0 !important;"
                            >
                              <v-icon style="color: #424242"
                                >mdi-dots-vertical</v-icon
                              >
                            </v-btn>
                          </template>
                          <v-list class="d-flex flex-column align-start">
                            <div>
                              <v-list-item
                                class="list-item-padding"
                                v-show="loginmemberID == repcomment.memberID"
                              >
                                <v-btn
                                  color="#424242"
                                  style="
                                    font-size: 16px;
                                    width: 100px;
                                    justify-content: left;
                                  "
                                  text
                                  class="text-capitalize"
                                  @click="editCommentReply(repcomment, comment)"
                                >
                                  <v-icon left>mdi-square-edit-outline</v-icon>
                                  {{ $t("edit") }}
                                </v-btn>
                              </v-list-item>
                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="#FF6060"
                                  style="width: 100px; justify-content: left"
                                  text
                                  class="text-capitalize"
                                  @click="
                                    checkUpdateDeleteReplyMessage(repcomment)
                                  "
                                >
                                  <v-icon left>mdi-delete-outline</v-icon>
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-list-item>
                            </div>
                          </v-list>
                        </v-menu>
                      </div>
                    </template>
                  </v-col>
                  <!-- inner reply box -->
                  <v-col
                    cols="12"
                    md="12"
                    class="reply-textbox-paddingLeft"
                    v-if="repcomment.showInside_ReplyBox"
                    style="display: flex; justify-content: center"
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="11"
                        class="reply-textbox-paddingLeft d-flex flex-column"
                      >
                        <div class="d-flex" style="width: 100%">
                          <v-avatar
                            class="marginRight-6"
                            justify="center"
                            align="center"
                            size="40"
                          >
                            <v-img
                              v-if="profileImg === null"
                              src="@/assets/defaultprofile.png"
                            ></v-img>
                            <v-img v-else :src="profileImg"></v-img>
                          </v-avatar>
                          <div class="d-flex" style="width: 100%">
                            <v-text-field
                              :placeholder="$t('writeareply')"
                              v-model="repcomment.tempCommentReplyMsg"
                              @input="
                                checkCommentLength(
                                  repcomment.tempCommentReplyMsg,
                                  5
                                )
                              "
                              hide-details
                              class="comment-textbox"
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div
                          class="mt-3 d-flex justify-end"
                          style="width: 100%"
                        >
                          <v-btn
                            depressed
                            text
                            outlined
                            width="80"
                            height="35"
                            class="text-capitalize ml-5"
                            style="border-radius: 8px; margin-left: 5px"
                            @click="closeReplyMessage(repcomment)"
                          >
                            {{ $t("cancel") }}
                          </v-btn>
                          <v-btn
                            :disabled="replyInnerComNull"
                            class="text-capitalize btn_hover_effect ml-5"
                            style="
                              color: #ffff;
                              border-radius: 8px;
                              font-size: 14px;
                              min-width: 80px !important;
                              min-height: 35px !important;
                              width: auto !important;
                              padding: 0;
                            "
                            color="#A6CC39"
                            @click="AddReplyToComment(comment, repcomment)"
                          >
                            {{ $t("reply") }}
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </template>
      </template>
    </v-card>
    <!-- Popup Alert For Message Check -->
    <v-dialog
      v-model="deleteDialog"
      max-width="470px"
      max-height="87px"
      style="border-radius: 15px !important"
    >
      <v-card style="border-radius: 15px !important">
        <v-spacer></v-spacer>
        <v-card-title class="flex justify-center items-center mb-4">
          <p class="mb-0" style="font-size: 20px; font-weight: bold">
            {{$t("deletecomment")}}
          </p>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="closemessageDialog()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-left">
          <p style="font-size: 20px; color: #363636; opacity: 1" class="mb-1">
            {{$t("doyouwanttodeletecomment")}}
          </p>
        </v-card-text>
        <v-divider class="mb-3" style="opacity: 0.5"></v-divider>
        <v-card-actions class="justify-end" style="padding-bottom: 20px">
          <v-btn
            width="70"
            height="39"
            class="text-capitalize ml-5"
            style="border-radius: 8px; font-size: 14px"
            color="#F0F0F0 "
            @click="closemessageDialog()"
            >{{$t("No")}}</v-btn
          >
          <v-btn
            width="76"
            height="39"
            class="text-capitalize btn_hover_effect ml-5"
            style="
              color: #ffff;
              border: 1px solid #a6cc39;
              border-radius: 8px;
              font-size: 14px;
            "
            color="#A6CC39"
            @click="
              deleteType === 'comment'
                ? confirmDelete(deleteData.commentID)
                : confirmUpdateDelete(deleteReplydata.replyID)
            "
            >{{$t("Yes")}}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SuccessDialog
      :show="deletesuccessDialog"
      :title="$t('success')"
      :text="$t('deletecommentsuccessful')"
      @close="
        (deletesuccessDialog = false), GetcommendData(self.CaseStudy.id, Page)
      "
    />
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      memberID: "",
      replyInnerComNull: true,
      editReplyNull: true,
      deleteType: null,
      replyCommentNull: true,
      deletesuccessDialog: false,
      deleteDialog: false,
      contentid: 0,
      Page: 5, // for knowledge sharing page id
      showUpdateBtnFlag: false,
      commentNull: false,
      loginmemberID: localStorage.getItem("memberID"),
      mainComNull: true,
      messageItems: [],
      profileImg: localStorage.getItem("profileimage"),
      new_comment: "",
      hasLink: false,
      CaseStudy: [],
      Addfav: false,
      wordContent: "",
      pdfurl: "",
      breadcrumbs: [
        {
          text: "lessonlearn",
          href: "/lessonlearn",
        },
        {
          text: this.$route.query.lessonlearn_title,
        },
      ],
      maxlength: 20,
    };
  },
  watch: {
    "CaseStudy.link"(newValue) {
      this.hasLink = newValue != null && newValue.trim().length > 0;
    },
  },
  computed: {
    heartIcon() {
      return this.Addfav == true ? "mdi-heart" : "mdi-heart-outline";
    },
  },
  mounted() {
    this.getFavourite();
    this.getNotificationCount();

    this.GetCaseStudiesByID();
    if (this.$store.state.library.notificationTransactionIDForLesson != 0) {
      this.Readnoti(
        this.$store.state.library.notificationTransactionIDForLesson,
        4
      );
    }
    this.UpdateCompleteLessonLearn();
    var params = this.$route.query;
    this.contentid = params.lessonlearn_id;
    this.GetcommendData(this.contentid);
    //this.GetcommendData(self.contentID);
  },
  methods: {
    replyToInnerComment(repcomment) {
      let self = this;
      self.messageItems.forEach((element) => {
        element.replyCommentLists.forEach((item) => {
          (item.showInside_ReplyBox = false),
            (item.showEditReplyBox = false),
            (item.tempEditReplyText = "");
        });
      });
      repcomment.showInside_ReplyBox = true; //item.showOuterCommentBox = true
      self.replyInnerComNull = true;
      //self.showUpdateBtnFlag = false;
    },
    checkCommentLength(v, type) {
      if (v.length > 100) {
        alert("Message should be less than 100 characters");
        v = v.substring(0, 99) + "";
      }
      switch (type) {
        case 1:
          this.mainComNull = v.length === 0;
          break;
        case 2:
          this.commentNull = v.length === 0;
          break;
        case 3:
          this.replyCommentNull = v.length === 0;
          break;
        case 4:
          this.editReplyNull = v.length === 0;
          break;
        case 5:
          this.replyInnerComNull = v.length === 0;
          break;
      }
    },
    cancelMessage(item) {
      let self = this;

      item.showOuterCommentBox = false;
      self.showUpdateBtnFlag = false;
      self.commentNull = false;
      //self.replyMessage = "";
    },
    Click_Inner_Reply(repcomment) {
      let self = this;
      self.messageItems.forEach((element) => {
        element.replyCommentLists.forEach((item) => {
          (item.showInside_ReplyBox = false),
            (item.showEditReplyBox = false),
            (item.tempEditReplyText = "");
        });
      });
      repcomment.showInside_ReplyBox = true; //item.showOuterCommentBox = true
      self.showUpdateBtnFlag = false;
    },
    editCommentReply(repcomment, comment) {
      let self = this;

      self.messageItems.forEach((element) => {
        element.replyCommentLists.forEach((item) => {
          (item.showInside_ReplyBox = false),
            (item.showEditReplyBox = false),
            (item.tempEditReplyText = "");
        });
      });

      self.messageItems[comment.no - 1].replyFlag = true;
      self.messageItems[comment.no - 1].replyCommentLists[
        repcomment.no - 1
      ].showEditReplyBox = true;
      self.messageItems[comment.no - 1].replyCommentLists[
        repcomment.no - 1
      ].tempEditReplyText = repcomment.replyMessage;

      self.updateReplyData = repcomment;
      self.showUpdateBtnFlag = true;
      self.editReplyNull = repcomment.replyMessage.length === 0;
    },

    checkUpdateDeleteReplyMessage(repcomment) {
      let self = this;
      self.deleteType = "reply";
      self.deleteDialog = true;
      //self.deleteReplyMessageDialog = true;
      self.deleteReplydata = repcomment;
    },
    closeReplyMessage(item) {
      item.showInside_ReplyBox = false;
      item.tempCommentReplyMsg = "";
      this.commentNull = false;
    },
    async updateReplyMessage(updateReplyData, comment) {
      let self = this;
      self.editReplyNull = true;
      const request = {
        replyID: updateReplyData.replyID,
        replyMessage: updateReplyData.tempEditReplyText, //self.replyMessage,
      };
      axios
        .post(`${self.web_url}Comment/UpdateReplyComment`, request)

        .then(function (result) {
          if (result.data.status == 0) {
            updateReplyData.replyFlag = false;
            comment.replyFlag = false;
            self.GetcommendData(self.CaseStudy.id, self.Page);
          }
          self.commentNull = false;
        });
    },
    AddReplyToComment(item, reply) {
      let self = this;
      self.replyCommentNull = true;
      self.replyInnerComNull = true;
      let tempReplyID = reply?.replyID || null;
       if(reply != null && reply.memberID == self.loginmemberID){
        tempReplyID = null;
      }
      let tempReply_msg = reply?.tempCommentReplyMsg || "";
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        commentID: item.commentID,
        replyMessage: reply != null ? tempReply_msg : item.tempReplyMsg, //self.replyMessage,
        replyID: tempReplyID,
      };
      if (self.isEmpty(request.replyMessage)) {
        self.commentNull = true;
        return;
      }

      axios
        .post(`${self.web_url}Comment/AddReplyComment`, request)

        .then(function (result) {
          if (result.data.status == 0) {
            self.GetcommendData(self.CaseStudy.id, self.Page);
          }
          self.commentNull = false;
        });
    },
    cancelComment(com) {
      let self = this;
      com.editCommentFlag = false;
      self.showUpdateBtnFlag = false;
      self.commentNull = false;
    },
    editComment(com) {
      let self = this;
      self.messageItems.forEach((element) => {
        element.editCommentFlag = false;
        element.replyFlag = false;
        element.showOuterCommentBox = false;
        element.replyCommentLists.forEach((item) => {
          (item.showInside_ReplyBox = false),
            (item.showEditReplyBox = false),
            (item.tempEditReplyText = ""),
            (item.tempCommentReplyMsg = "");
        });
      });

      self.messageItems[com.no - 1].editCommentFlag = true;
      self.messageItems[com.no - 1].tempComment = com.commentMessage;

      self.updateData = com; //?
      // self.replyMessage = com.commentMessage;
      com.editCommentFlag = true;
      self.showUpdateBtnFlag = true;
    },
    ClickCommentReply(comment) {
      let self = this;
      self.messageItems.forEach((element) => {
        element.editCommentFlag = false;
        element.replyFlag = false;
        element.showOuterCommentBox = false;
        element.tempReplyMsg = "";
        element.replyCommentLists.forEach((item) => {
          (item.showInside_ReplyBox = false),
            (item.showEditReplyBox = false),
            (item.tempEditReplyText = ""),
            (item.tempCommentReplyMsg = "");
        });
      });

      //comment.showOuterCommentBox = true;
      self.messageItems[comment.no - 1].showOuterCommentBox = true;
    },
    showReplylist(comment) {
      comment.replyFlag = !comment.replyFlag;
    },
    async GetcommendData(contentID) {
      let self = this;
      (self.new_comment = ""),
        await this.$axios
          .get(
            `${self.web_url}Comment/GetComments?VideoID=${contentID}&Page=${self.Page}`
          )
          .then(function (res) {
            console.log("getdata", res.data.data);
            if (res.data.data.length > 0) {
              self.messageItems = res.data.data.map((v, i) => ({
                ...v,
                no: i + 1,
                replyFlag: false,
                editCommentFlag: false,
                showOuterCommentBox: false,
                showOuterReplyBox: false,
                tempComment: "",
                tempReplyMsg: "",
                replyCommentLists: v.replyCommentLists.map((j, k) => ({
                  ...j,
                  no: k + 1,
                  tempEditReplyText: "",
                  tempCommentReplyMsg: "",
                  showEditReplyBox: false,
                  showInside_ReplyBox: false,
                })),
              }));
              self.loading = false;
            } else {
              self.loading = false;
            }
          });
    },

    isEmpty(value) {
      return (
        value == null ||
        (typeof value === "string" && value.trim().length === 0)
      );
    },
    AddCommentUnderVideo() {
      let self = this;
      self.mainComNull = true;

      const request = {
        companyID: localStorage.getItem("companyID"),
        userID: parseInt(localStorage.getItem("UserID")),
        videoID: parseInt(self.$route.query.lessonlearn_id),
        updateBy: self.updateBy,
        createdBy: self.createdBy,
        commentMessage: self.new_comment,
        Page: self.Page,
      };
      axios
        .post(`${self.web_url}Comment/AddComment`, request)

        .then(function (result) {
          if (result.data.status == 0) {
            //self.addsuccessDialog = true;
            self.GetcommendData(self.CaseStudy.id, self.Page);
          }
        });

      self.new_comment = "";
    },
    async updateComment(updateData) {
      let self = this;
      self.commentNull = true;
      // if (self.isEmpty(updateData.tempComment)) {
      //   self.commentNull = true;
      //   return;
      // }

      self.messageID = updateData.commentID;
      const request = {
        companyID: localStorage.getItem("companyID"),
        userID: parseInt(localStorage.getItem("UserID")),
        commentID: self.messageID,
        updateBy: self.updateBy,
        createdBy: self.createdBy,
        commentMessage: updateData.tempComment,
      };
      axios
        .post(`${self.web_url}Comment/UpdateComment`, request)

        .then(function (result) {
          if (result.data.status == 0) {
            self.GetcommendData(self.CaseStudy.id, self.Page);
            self.showUpdateBtnFlag = false;
          }
          self.commentNull = false;
        });
    },
    async checkMessageDelete(comment) {
      let self = this;
      self.deleteDialog = true;
      self.deleteData = comment;
      self.deleteType = "comment";
    },
    async confirmDelete(ID) {
      let self = this;
      self.loading = true;
      await axios
        .post(`${self.web_url}Comment/DeleteComment?CommentID=` + ID)
        .then(function (res) {
          if (res.data.data && res.data.data.length > 0) {
            //  self.deletesuccessDialog = true;
            self.deleteDialog = false;
            self.GetcommendData(self.CaseStudy.id, self.Page);
            self.loading = false;
          }
        })
        .catch(function (err) {
          alert(err);
        });
    },
    closemessageDialog() {
      this.deleteDialog = false;
    },
    async confirmUpdateDelete(ID) {
      let self = this;
      self.loading = true;
      await axios
        .post(`${self.web_url}Comment/DeleteReplyComment?replyID=` + ID)
        .then(function (res) {
          if (res.data.status == 0) {
            // self.deletesuccessDialog = true;
            //self.deleteReplyMessageDialog = false;
            self.deleteDialog = false;
            self.GetcommendData(self.CaseStudy.id, self.Page);
            self.loading = false;
          }
        })
        .catch(function (err) {
          alert(err);
        });
    },
    cutText(v) {
      if (v.length <= this.maxlength) {
        return v;
      } else {
        return v.substring(0, this.maxlength) + "...";
      }
    },
    clickPDFLink(link) {
      window.open(link, "_blank");
    },
    clickLink(link) {
      if (!link.startsWith("http://") && !link.startsWith("https://")) {
        link = "http://" + link;
      }
      // Open the link in a new tab
      window.open(link, "_blank");
    },
    toggleCheck() {
      this.Addfav = !this.Addfav;
      this.updateFavourite(this.Addfav);
    },
    updateFavourite(isFavourite) {
      let self = this;
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        contentID: self.CaseStudy.id,

        // self.contentid: self.CaseStudy.id,
        contentCheck: 4,
        favFlag: isFavourite,
      };
      axios
        .post(`${this.web_url}Contents/UpdateFavorite`, request)
        .then(function (result) {
          if (result.data.status == 0) {
            if (isFavourite == true) {
              self.$store.commit(
                "savefavCount",
                self.$store.state.library.favCount + 1
              );
            } else {
              self.$store.commit(
                "savefavCount",
                self.$store.state.library.favCount - 1
              );
            }
          }
        });
    },
    AddReportActivity(id) {
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        id: id,
        minutes: "1",
        separatePage: 1,
        progressTime: "",
        actualViewTime: "",
      };
      axios.post(`${this.web_url}Reports/AddReportActivity`, request);
    },
    async GetCaseStudiesByID() {
      let self = this;
      await axios
        .get(
          `${self.web_urlV5}CaseStudies/GetCaseStudiesByID?id=` +
            self.$route.query.lessonlearn_id +
            `&UserID=` +
            parseInt(localStorage.getItem("UserID"))
        )

        .then(function (res) {
          self.memberID = res.data.data.memberID;
          self.contentid = res.data.data.id;
          self.Addfav = res.data.data.favFlag;
          self.CaseStudy = res.data.data;
          self.AddReportActivity(res.data.data.id);
        });
    },
    downloadFile() {
      this.$axios({
        url: "CaseStudy.pdfUrl",
        method: "GET",
        responseType: "blob",
      }).then((res) => {
        let FILE = window.URL.createObjectURL(new Blob([res.data]));

        let docUrl = document.createElement("x");
        docUrl.href = FILE;
        docUrl.setAttribute("download", "file.pdf");
        document.body.appendChild(docUrl);
        docUrl.click();
      });
    },
    Readnoti(notificationid, pageid){
      var self = this;
      const request = {
            userID: parseInt(localStorage.getItem("UserID")),
            notificationID: notificationid,
            notiPage: pageid
        };
        axios.post(
            `${self.web_url}Notification/AddUserIDinNotiJoin`,
            request
        ).then(function(result) {
          if(result.data.status == 0){
            self.getNotificationCount();
          }
        });
    },
    async getNotificationCount(){
      var self = this;
        const request = {
            userId: parseInt(localStorage.getItem("UserID")),
            companyId: localStorage.getItem("companyID"),
        };
         await axios.post(
            `${self. web_urlV3}Notification/GetAllNotificationMobileV4`,
            request
        ).then(function(result){
           if(result.data.status == 0){
          var d1 = result.data.data.filter(x=>x.viewOrNot == false).length;
          var d2 = result.data.data1.filter(x=>x.newNoti == false).length;
          var d3 = result.data.data2.filter(x=>x.newNoti == false).length;
          var d4 = result.data.data4.filter(x=>x.newNoti == false).length;
          var d5 = result.data.data5.filter(x=>x.newNoti == false).length;
          var d6 = result.data.data6.filter(x=>x.newNoti == false).length;
          var d7 = result.data.data7.filter(x=>x.newNoti == false).length;
          var d8 = result.data.data8.filter(x=>x.newNoti == false).length;
          let d9 = result.data.data3.filter(x=>x.newNoti === false).length;
          var notiCount = d1 + d2 + d3 + d4 + d5 + d6 + d7 + d8 + d9;
          self.$store.commit("savenotiCount", notiCount);
        }
        });

    },
    async UpdateCompleteLessonLearn() {
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        lessonID: parseInt(this.$route.query.lessonlearn_id),
      };
      axios.post(
        `${this.web_url}CaseStudies/UpdateCompleteLessonLearn`,
        request
      );
    },
  },
};
</script>

<style scoped>
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}
::v-deep .comment-textbox {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}
.btn_hover_effect,
.reset-btn {
  color: #fff;
  width: 80%;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.btn_hover_effect:before,
.reset-btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.btn_hover_effect:hover:before,
.reset-btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.btn_hover_effect:hover,
.reset-btn:hover {
  transition: 0.25s;
}
.btn_hover_effect:after,
.reset-btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
::v-deep .v-dialog {
  overflow-x: hidden;
  overflow-y: hidden;
}
.scrollbar-container {
  padding-right: 5px;
  height: auto;
  padding: 0 0 1rem 0;
}
.video-scrollbar {
  /* padding-bottom: 2.5rem; */
  padding: 0 2.4rem 0 1.7rem;
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}
@media screen and (max-width: 768px) {
  .scrollbar-container {
    padding-right: 5px;
    height: auto;
    padding-bottom: 1rem;
    padding-left: 0px;
  }
  .video-scrollbar {
    padding-bottom: 2rem;
    height: 30vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
  }
}
</style>
<style scoped>
.custom-scrollbar {
  /* max-height: 500px; */
  padding-bottom: 2.5rem;
  height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* Safari and Chrome */
}
.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 5px; /* Adjust radius as needed */
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 15px; /* Adjust radius as needed */
}
@media screen and (min-width: 1920px) {
  .custom-scrollbar {
    padding-bottom: 2.5rem;
    height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
}
.test {
  display: flex;
  justify-content: center;
}
.border1 {
  height: 1px;
  width: 95%;
  background-color: #00000027;
}
.breaktext {
  white-space: pre-line;
}
.orgImg {
  width: 600px;
  height: 375px;
  border-radius: 20px !important;
}
.Caseicon {
  left: 20px;
  color: #ff6060 !important;
  font-size: 45px !important;
  top: 18px;
}
.login-phone {
  color: #fff;
  font-size: 18px;
  z-index: 0;
  position: relative;
}
.login-phone:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.login-phone:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.login-phone:hover {
  transition: 0.25s;
}
.login-phone:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}

/* Heart icon */
.heart-icon:hover {
  cursor: pointer;
}
.heart-icon {
  top: 20px;
  font-size: 50px;
  width: 50px;
}
.textlink {
  color: blue !important;
}
.Casetext {
  color: rgb(66, 66, 66);
  font-weight: bold;
  font-size: 23px;
}
.comment-paddingLeft4 {
  padding-left: 16px !important;
}
.marginRight-6 {
  margin-right: 24px !important;
}
.reply-textbox-paddingLeft {
  padding-left: 60px !important;
}
@media only screen and (max-width: 600px) {
  .marginRight-6 {
    margin-right: 5px !important;
  }
  .reply-textbox-paddingLeft {
    padding-left: 0px !important;
  }
  .comment-paddingLeft4 {
    padding-left: 10px !important;
  }
  .video-scrollbar {
    padding: 0;
    height: 25vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
  }
}
</style>
